import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { screen } from '../utils'
import Wysiwyg from '../components/General/WysiwygContent'
import PageWrap from '../components/Layouts/Global/PageWrap'
import bgWaves from '../images/pageBgs/bg-waves.jpg'
import bgLinkGraphic from '../images/pageAssets/link_graphic.png'

const PageInterior = styled.div`
  background-image: url(${bgWaves});
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 80% auto;

  ${screen('sm')} {
    background-size: 50% auto;
  }

  ${screen('xl')} {
    background-size: 30% auto;
  }

  ${tw`
    max-w-4xl
    mx-auto
    pt-6
    xl:px-3
  `}
`

const SubPage = () => {
  return (
    <PageWrap
      darkMode
      disableVerticalPadding
      bg={false}
      className="-mb-6 lg:-mb-9 bg-black"
    >
      <div className="relative -mx-6 xl:mx-auto" style={{ minHeight: '65vh' }}>
        <video
          className="absolute pin w-full h-full z-0"
          src="https://algorandcom.cdn.prismic.io/algorandcom/c4cf09ed-439c-4a2b-a5e9-d266bdbbc173_home_page_background_video.mp4"
          autoPlay
          muted
          loop
          style={{ objectFit: 'cover' }}
        ></video>
      </div>
      <PageInterior>
        <Wysiwyg darkMode>
          <div className="lg:w-3/4 pb-6">
            <h1 className="uppercase mb-4">
              Algorand Selected to Power
              <br />
              El Salvador Digital Currency
            </h1>
            <h2>THIS IS HEADLINE COPY FOR THE Subsection</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
              accumsan lacus vel facilisis. sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
              gravida. Risus commodo viverra maecenas accumsan lacus vel
              facilisis.
            </p>
            <p>
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
              maecenas accumsan lacus vel facilisis. sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Quis ipsum
              suspendisse ultrices gravida. Risus commodo viverra maecenas
              accumsan lacus vel facilisis. sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
              gravida. Risus commodo viverra maecenas accumsan lacus vel
              facilisis. sed do eiusmod tempor incididunt ut labore et dolore
              magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus
              commodo viverra maecenas accumsan lacus vel facilisis.
            </p>
            <p>
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
              maecenas accumsan lacus vel facilisis. sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Quis ipsum
              suspendisse ultrices gravida. Risus commodo viverra maecenas
              accumsan lacus vel facilisis. sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
              gravida. Risus commodo viverra maecenas accumsan lacus vel
              facilisis. sed do eiusmod tempor incididunt ut labore et dolore
              magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus
              commodo viverra maecenas accumsan lacus vel facilisis.
            </p>
            <p>
              {' '}
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
              maecenas accumsan lacus vel facilisis. sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Quis ipsum
              suspendisse ultrices gravida. Risus commodo viverra maecenas
              accumsan lacus vel facilisis. sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
              gravida. Risus commodo viverra maecenas accumsan lacus vel
              facilisis. sed do eiusmod tempor incididunt ut labore et dolore
              magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus
              commodo viverra maecenas accumsan lacus vel facilisis.
            </p>
          </div>
        </Wysiwyg>
        <div className="pb-9">
          <div className="flex items-end mb-4">
            <img className="w-24 h-auto flex-no-shrink" src={bgLinkGraphic} />
            <h2 className="text-green-3 uppercase bold -ml-8">
              <span className="ml-2">Relevant Links</span>
              <br />
              and Resources
            </h2>
          </div>
          <ul className="p-0">
            <li className="mb-3">
              <a href="#" className="text-white hover:no-underline text-xl">
                Lorem ipsum dolor sit amet
              </a>
            </li>
            <li className="mb-3">
              <a href="#" className="text-white hover:no-underline text-xl">
                Lorem ipsum dolor sit amet
              </a>
            </li>
            <li className="mb-3">
              <a href="#" className="text-white hover:no-underline text-xl">
                Lorem ipsum dolor sit amet
              </a>
            </li>
            <li>
              <a href="#" className="text-white hover:no-underline text-xl">
                Lorem ipsum dolor sit amet
              </a>
            </li>
          </ul>
        </div>
      </PageInterior>
    </PageWrap>
  )
}

export default SubPage
