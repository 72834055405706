import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'

const StyledContent = styled.div`
  ${tw`
    font-sans
  `}

  ${(props) => (props.extraSmallText ? `font-size: 0.9rem;` : tw`text-base`)}
  ${(props) => !props.smallText && tw`lg:text-lg`}
  ${(props) => (props.darkMode ? tw`text-white` : tw`text-dark-grey-2`)}

  h1 {
    ${tw`
      font-bold
      text-2xl
      lg:text-4xl
      leading-snug
      mb-4
    `}
  }

  h2 {
    ${tw`
      font-bold
      text-xl
      lg:text-2xl
      leading-normal
      mb-3
    `}
  }

  h3,
  h4,
  h5,
  h6 {
    ${tw`
      font-bold
      text-lg
      leading-normal
      mb-3
    `}
  }

  p {
    ${tw`
      font-light
      leading-normal
      mb-3
    `}
  }

  strong {
    ${tw`
      font-bold
    `}
  }

  [data-oembed] {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  ul {
    ${tw`
      leading-relaxed
      mb-3
    `}
  }

  li {
    ${tw`
      font-light
      leading-relaxed
      pl-2
      mb-1
    `}
    ${(props) => (props.darkMode ? tw`text-white` : tw`text-dark-grey-2`)}
  }

  a {
    ${tw`
      text-green-3
      font-bold
      no-underline
      hover:underline
    `}
  }

  button {
    ${tw`
      mx-0
      mb-3
    `}
  }

  pre {
    ${tw`
      bg-light-grey-1
      p-2
      mb-3
    `}
  }

  .block-img {
    text-align: center;

    &.left-align {
      float: left;
      margin-bottom: 20px;
      margin-right: 20px;
    }

    &.right-align {
      float: right;
      margin-bottom: 20px;
      margin-left: 20px;
    }

    &.center-align {
      text-align: center;
    }
  }
`

const Wysiwyg = ({
  content,
  children,
  smallText,
  extraSmallText,
  centered,
  darkMode,
}) => (
  <StyledContent
    extraSmallText={extraSmallText}
    smallText={smallText}
    className={centered ? 'text-center' : ''}
    darkMode={darkMode}
  >
    {content && <div dangerouslySetInnerHTML={{ __html: content }}></div>}
    {children}
  </StyledContent>
)

export default Wysiwyg
